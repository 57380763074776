/* Responsive media query for large screens */
@media (max-width: 1500px) {  /* Adjust breakpoint as needed */
    .btn-info,
    .btn-danger {
        margin-right: 10px;  /* Add a gap between buttons */
    }
}

/* Responsive media query for large screens */
@media (max-width: 300px) {  /* Adjust breakpoint as needed */
    .btn-info,
    .btn-danger {
        margin-right: 10px;
        width: 100%;  /* Add a gap between buttons */
    }
}

/* For all screen sizes (optional, for default spacing) */
.btn-info,
.btn-danger {
     margin-bottom: 5px;  /* Add vertical spacing for single-column layout */
}