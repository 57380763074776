/* Responsive media query for large screens */
@media (min-width: 1000px) {  /* Adjust breakpoint as needed */
    
    th {
        text-align: right;
    }

    .btn-info
    {
        margin-right: 10px; 
    }

    
}

/* Responsive media query for large screens */
@media (max-width: 700px) {  /* Adjust breakpoint as needed */
    
    th {
        text-align: center;
    }

    .btn-info,
    .btn-danger {
        margin-right: 10px;
        width: 100%;  /* Add a gap between buttons */
    }

    
}

/* For all screen sizes (optional, for default spacing) */
.btn-info,
.btn-danger {
     margin-bottom: 5px;  /* Add vertical spacing for single-column layout */
}