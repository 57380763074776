
.dropdown-menu.show {
    background-color: aquamarine;
    border-color: black;
    box-shadow: 5px black;
  }
  
  .bg-dark {
    --bs-bg-opacity: 1;
    --bs-dark-rgb: 0, 152, 121; /* Set custom RGB color */
    /* background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important; */
    background-color: aquamarine !important;
  }
  
  #basic-nav-dropdown {
    color: black;
    font-size: large;
    font-weight: 500;
  }

  .close-button {
    position: absolute;
    top: 10px; /* Adjust top position as needed */
    right: 10px; /* Adjust right position as needed */
    z-index: 2; /* Ensure close button stays above other content */
  }