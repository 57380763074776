/* @media screen and (min-width: 1350px) {
    .Add-Customer {
        margin-right: -90px !important;
    }
} */


/* @media screen and (min-width: 600px) {
    .savebutton {
        margin-left: 40px !important;
        margin-top: 38px !important;
    }
} */


/* Add this to your CSS file */

.blur-background {
    filter: blur(5px);
    pointer-events: none;
    /* Ensure the background elements are not interactable */
}

.pop-up {
    position: fixed;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    pointer-events: auto;
    /* Ensure the pop-up elements are interactable */
}

.pop-up .billing-popup {
    border: 2px solid rgb(66, 41, 203);
    box-shadow: 10px 6px 9px rgb(62, 62, 62);
    background-color: white;
    padding: 20px;
}