@media print {
    @page {
      size: A6;
      margin: 0;
    }
  
    body {
      margin: 0;
      padding: 0;
    }
  
    .print-area {
      width: 100%;
      height: 100%;
    }
  }
  